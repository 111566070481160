<template>

<div @click="$router.back()" class="back-btn" v-if="this.$route.name.includes('Quotes')">
    <icon :icon="'back-arrow'" />
    <p>Retour</p>
  </div>
  <div v-else @click="$router.push(to)" class="back-btn">
    <icon :icon="'back-arrow'" />
    <p>Retour</p>
  </div>
</template>

<script>
export default {
  name: "BackBtn",
  props: {
    to: {
      type: Object,
      default: () => {
        return { name: 'clientsListing' }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .back-btn {
    @apply absolute flex cursor-pointer;
    top: 105px;
    left: 20px;

    @screen tablet {
      top: 130px;
      left: 30px;
    }

    p { @apply ml-2; }
  }
</style>
