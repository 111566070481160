<template>
  <div class="quote-sidebar">
    <div class="products">
      <h2>Simulation économique des solutions Bioret Agri</h2>
      <div
          v-for="(produit, index) in produitsDevis"
          :key="index"
        >
        <div class="product">
          <p v-if="produit.name" class="title">{{ produit.name }}</p>
          <p v-if="produit.qte">Quantité : {{ produit.qte }}</p>
          <p v-if="produit.prix">Prix unitaire : <span class="font-mono-large">{{ parseFloat(produit.prix).toLocaleString(undefined, {minimumFractionDigits: 2}) }} € HT</span></p>
          <p v-if="produit.prix && produit.qte">
            Prix total : <span class="font-mono-large">{{ (parseFloat(produit.prix) * parseInt(produit.qte)).toLocaleString(undefined, {minimumFractionDigits: 2}) }} € HT</span>
          </p>
        </div>
      </div>
    </div>
    <div class="quote-sidebar-bottom">
      <div class="ri">
        <p>Sans financement sur 10 ans :</p>
        <p class="font-bold font-16" v-if="seuilRentabilite >= 1"
        >Seuil de rentabilité {{ seuilRentabilite + ' ' + (seuilRentabilite >= 2 ? 'ans' : 'an') }}
      </p>
        <p class="font-bold font-16" v-else>
          Non calculé, revoyez votre projet
        </p>
        <p>Retour d’investissement sur 10 ans en % :</p>
        <p class="font-mono font-bold font-16">{{ ri.length === 10 ? ' ' + Math.round(ri[9] * 100).toLocaleString() + ' %'  : 'Non calculé' }}</p>
      </div>
      <div class="total-price">
        Montant de votre projet *<br>
        <div class="font-mono-large">{{ parseFloat(this.$store.state.quotes.current.prixHT).toLocaleString(undefined, {minimumFractionDigits: 2}) }} € HT</div>
        <p class="mt-5 text-right text-white-900">*Hors main d’œuvre pose & transport</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sidebar",

  computed: {
    produitsDevis() {
      return this.$store.state.quotes.current.produits
    },
    ri() {
      return this.$store.state.quotes.current.ri
    },
    seuilRentabilite() {
      return this.$store.state.quotes.current.seuilRentabilite
    }
  }
}
</script>

<style lang="scss" scoped>
.font-mono {
  font-family: monospace;
  font-size: 12px;
}

.font-mono-large {
  font-family: monospace;
}

.quote-sidebar .ri p {
    font-size: 14px;
}

.quote-sidebar .ri .font-16 {
  font-size: 16px;
}
</style>
