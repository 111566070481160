<template>
    <modal close-left>
      <button slot="activator" class="btn btn-red">Détails de financement du projet</button>

      <div ref="quoteChart" class="quote-chart">

        <div class="quote-chart-header">

          <p>Récapitulatif de <strong>votre projet Bioret Agri</strong></p>

          <p class="quote-chart-header-investment">Coût de l'investissement * : <span class="font-mono-large">{{ prixHT }}</span></p>

        </div>

        <div class="quote-chart-cards">

          <div class="quote-chart-cards-benefits">
            <p>Gains nets sur 10 ans</p>
            <p>
              <span class="font-mono-large">{{ gains }}</span><br>
             <div v-if="isGainsCalculated"> {{ rentabilisationAnnee ? '(Projet rentabilisé dès ' + rentabilisationAnnee + ')' : '' }}</div>
            </p>
          </div>

          <div class="quote-chart-cards-treasury7">
            <p>Solde de trésorerie cumulé sur 10 ans avec un financement sur 7 ans**</p>
            <p><span class="font-mono-large">{{ tresorerie7Annee10 }}</span></p>
          </div>

          <div class="quote-chart-cards-treasury10">
            <p>Solde de trésorerie cumulé avec un financement sur 10 ans**</p>
            <p><span class="font-mono-large">{{ tresorerie10Annee10 }}</span></p>
          </div>

        </div>

        <p class="mt-2 text-right text-gray-900">*Hors main d’œuvre pose & transport</p>
        <p class="mt-2 text-right text-gray-900">** à un taux de 4% par an et mensualités constantes</p>


        <div class="quote-chart-graph">
          <p class="mt-2 text-center text-gray-900" style="font-style: italic;font-size:14px;">Cliquez sur les légendes pour afficher ou cacher les courbes.</p>
          <LineChartGenerator
            :chart-options="chartOptions"
            :chart-data="chartData"
            chart-id="ri-chart"
            dataset-id-key="labels"
          />

        </div>

        <p class="text-gray-900 text-center">Simulation non contractuelle</p>

        <div class="mt-10 quote-chart-logo" v-if="$route.name === 'clientsListing'">
          <div class="logo">
          <Logo />
        </div>
        </div>

      </div>

    </modal>

</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation';
import Logo from "@/components/commons/Logo";

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement, annotationPlugin)

export default {
  name: 'Chart',

  components: { LineChartGenerator, Logo },

  data: function () {
    return {
      chartData: {
        labels: [ '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10' ],
        datasets: [
          {
            label: 'Solde de trésorerie cumulé avec un financement sur 7 ans',
            backgroundColor: '#616161',
            borderColor: '#616161',
            borderWidth: 3,
            pointStyle: 'rect',
            pointRadius: 8,
            data: []
          },
          {
            label: 'Solde de trésorerie cumulé avec un financement sur 10 ans',
            backgroundColor: '#ef5407',
            borderColor: '#ef5407',
            borderWidth: 3,
            pointStyle: 'rect',
            pointRadius: 8,
            hidden: true,
            data: []
          },
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            title: {
              display: true,
              text: 'Années'
            }
          }
        },
        plugins: {
          autocolors: false,
          annotation: {
            annotations: {
              line0: {
                type: 'line',
                yMin: 0,
                yMax: 0,
                borderColor: '#616161',
                borderWidth: 1,
              }
            }
          },
          legend: {
            labels: {
              generateLabels: (chart) => {
                return chart.data.datasets.map((dataset, i) => {
                  return {
                    text: dataset.label,
                    datasetIndex: i,
                    fontColor: chart.isDatasetVisible(i) ? 'rgba(0, 0, 0, 1)' : 'rgba(0, 0, 0, 0.5)',
                    fillStyle: dataset.backgroundColor,
                    strokeStyle: dataset.backgroundColor
                  }
                })
              }
            }
          }
        }
      }
    }
  },

  computed: {
    values() {
      const quote = this.$store.state.quotes.current
      return { ...quote.elevage, ...quote.logettes, ...quote.batiment }
    },

    prixHT() {
      return this.$store.state.quotes.current.prixHT > 0 ?
      // Math.round(this.$store.state.quotes.current.prixHT).toLocaleString() + ' € HT' : 'Non calculé'
      parseFloat(this.$store.state.quotes.current.prixHT).toLocaleString(undefined, {minimumFractionDigits: 2}) + ' € HT' : 'Non calculé'

    },

    gains() {
      return this.$store.state.quotes.current.gains > 0 ?
      Math.round(this.$store.state.quotes.current.gains).toLocaleString()  + ' €' : 'Non calculé'
    },

    isGainsCalculated() {
      return this.$store.state.quotes.current.gains > 0 ?
      true : false
    },

    rentabilisationAnnee() {
      return this.$store.state.quotes.current.rentabilisationAnnee
    },

    tresorerie7() {
      return this.$store.state.quotes.current.tresorerie7.length === 10 ?
        this.$store.state.quotes.current.tresorerie7 : []
    },

    tresorerie10() {
      return this.$store.state.quotes.current.tresorerie10.length === 10 ?
        this.$store.state.quotes.current.tresorerie10 : []
    },

    tresorerie7Annee10() {
      return this.$store.state.quotes.current.tresorerie7.length === 10 ?
        Math.round(this.$store.state.quotes.current.tresorerie7[9]).toLocaleString()  + ' €' : 'Non calculé'
    },

    tresorerie10Annee10() {
      return this.$store.state.quotes.current.tresorerie10.length === 10 ?
        Math.round(this.$store.state.quotes.current.tresorerie10[9]).toLocaleString()  + ' €' : 'Non calculé'
    },

    taxos() {
      return this.$store.state.quotes.taxos
    }
  },

  watch: {
    tresorerie7(newValue) {
      this.chartData.datasets[0].data = [0, ...newValue]
    },

    tresorerie10(newValue) {
      this.chartData.datasets[1].data = [0, ...newValue]
    }
  }
}
</script>

<style lang="scss" scoped>
.quote-chart-logo {
  display: flex;
  justify-content: center;
}

.font-mono-large {
  font-family: monospace;
}

</style>
