<template>
  <div class="dialog">
    <!--
      Utiliser le slot="activator" sur un composant pour activer une balise
      HTML qui héritera du comportement de la modale.
     -->
    <div
      v-if="$slots.activator"
      class="dialog-activator"
      @click="handleOpen">
      <slot name="activator"></slot>
    </div>

    <transition
      name="fade"
      mode="out-in">
      <div
        v-if="isOpen"
        class="dialog-window">

        <div @click="handleClose" class="close" :class="{ 'left': closeLeft }">
          <icon :icon="'close-modal'" />
        </div>

        <!-- Contenu de la modale -->
        <slot/>
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  props: {
    closeLeft: {
      type: Boolean,
      default: false,
      required: false
    },
    open: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    isOpen: false
  }),

  beforeMount() {
    if (this.open) {
      this.isOpen = true
      this.$emit('open')
    }
  },

  watch: {
    open(newVal) {
      this.isOpen = newVal
      if (newVal) {
        this.$emit('open')
        return
      }
      this.$emit('close')
    }
  },

  methods: {
    handleClose () {
      this.isOpen = false
      this.$emit('close')
    },

    handleOpen () {
      this.isOpen = true
      this.$emit('open')
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog {
  &-activator {
    @apply cursor-pointer;
  }

  &-window {

    .close {
      @apply absolute;
      top: 30px;
      right: 30px;

      &.left { left: 30px; }
    }

    @apply fixed flex flex-col justify-center items-center top-0 bottom-0 left-0 right-0 text-white z-50;
    background-color: var(--color-primary);
  }
}
</style>
