<template>
  <div>
    <BackBtn v-if="$route.name !== 'clientsListing'" :to="from" />
    <router-view />
  </div>
</template>

<script>
import BackBtn from "@/components/commons/BackBtn";
export default {
  name: 'Clients',

  components: { BackBtn },

  data() {
    return {
      from: undefined
    }
  },

  beforeRouteUpdate (to, from, next) {
    this.from = from
    next()
  }
}
</script>
