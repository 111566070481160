import axios from '@/axios'
import Vue from 'vue'
import ri from '@/helpers/ri'

const getTaxos = async ({ commit }) => {
  try {
    const taxos = await axios.get('/api/v1/taxos')
    commit('SET_TAXOS', taxos.data)
  } catch (e) {
    Vue.notify({
      group: 'notification',
      text: 'Une erreur est survenue lors de la récupération des options des champs select',
      type: 'error'
    })
  }
}

const getProduits = async ({ commit }) => {
  try {
    const produits = await axios.get('/api/v1/products')
    commit('SET_PRODUITS', produits.data)
  } catch (e) {
    Vue.notify({
      group: 'notification',
      text: 'Une erreur est survenue lors de la récupération de la liste des produits',
      type: 'error'
    })
  }
}

const getStationsMeteo = async ({ commit }, params) => {
  try {
    const stations = await axios.get('/api/v1/weather-stations', { params })
    commit('SET_STATIONS_METEO', stations.data)
  } catch (e) {
    Vue.notify({
      group: 'notification',
      text: 'Une erreur est survenue lors de la récupération des stations météos',
      type: 'error'
    })
  }
}

const getRiSettings = async ({ commit }) => {
  try {
    const riSettings = await axios.get('/api/v1/ri/settings')
    commit('SET_RI_SETTINGS', riSettings.data)
  } catch (e) {
    Vue.notify({
      group: 'notification',
      text: 'Une erreur est survenue lors de la récupération des paramètres de calcul du RI',
      type: 'error'
    })
  }
}

const getQuote = async ({ commit }, id) => {
  try {
    const quote = await axios.get('/api/v1/quotes/' + id)
    commit('SET_QUOTE', quote.data)
  } catch (e) {
    Vue.notify({
      group: 'notification',
      text: 'Une erreur est survenue lors de la récupération de la simulation',
      type: 'error'
    })
  }

}

const postQuote = async ({ state, commit }) => {
  try {
    const res = await axios.post('/api/v1/create_quote', state.current)
    commit('SET_SAVED_QUOTE', res.data.quoteId)

    Vue.notify({
      group: 'notification',
      text: 'La simulation a bien été créé',
      type: 'success'
    })
  } catch (e) {
    Vue.notify({
      group: 'notification',
      text: 'Une erreur est survenue lors de la création de la simulation',
      type: 'error'
    })
  }

}

const patchQuote = async ({ state }, id) => {
  try {
    await axios.patch('/api/v1/quotes/' + id, state.current)

    Vue.notify({
      group: 'notification',
      text: 'La simulation a bien été mise à jour',
      type: 'success'
    })
  } catch (e) {
    Vue.notify({
      group: 'notification',
      text: 'Une erreur est survenue lors de la mise à jour de la simulation',
      type: 'error'
    })
  }
}

const sendQuote = async ({ commit, rootState }, payload) => {
  try {
    await axios.get('/api/v1/quotes/' + payload.id + '/pdf', {
      params: { to: payload.to },
      // responseType: 'arraybuffer',
    })

    /*let blob = new Blob([pdf.data], { type: 'application/pdf' })
    let link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = 'Devis n°' + payload.filename + '.pdf'
    link.click()*/

    Vue.notify({
      group: 'notification',
      text: 'La simulation a bien été envoyée au client',
      type: 'success'
    })
  } catch (e) {
    Vue.notify({
      group: 'notification',
      text: 'Une erreur est survenue lors de l\'envoi de la simulation',
      type: 'error'
    })
  }
}

const resetQuotesState = ({ commit }) => {
  commit('RESET_QUOTES_STATE')
}

export default {
  getTaxos,
  getProduits,
  getStationsMeteo,
  getRiSettings,
  getQuote,
  postQuote,
  patchQuote,
  sendQuote,
  resetQuotesState
}
