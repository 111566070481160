const SET_CLIENTS = (state, value) => {
 state.listing = value
}

const SET_CLIENT = (state, value) => {
  state.current = value
}

const SET_SAVED_QUOTE = (state, value) => {
  state.savedQuote = value
}

export default {
  SET_CLIENTS,
  SET_CLIENT,
  SET_SAVED_QUOTE
}
