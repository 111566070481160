import actions from './actions'
import getters from './getters'
import mutations from './mutations'
const getDefaultState = () => {
  return {
    current: {
      companyName: '',
      lastname: '',
      firstname: '',
      address: '',
      postalCode: '',
      city: '',
      country: '',
      phone: '',
      email: ''
    },
    listing: [],
    savedQuote: ''
  }
}

const state = getDefaultState();

mutations.RESET_CLIENTS_STATE = (state) => {
  Object.assign(state, getDefaultState())
}

export default ({
  state: state,
  actions,
  mutations,
  getters
})
