<template>
  <div class="main">
    <div class="clients-listing">

      <div class="clients-listing-header">
        <PageTitle />

        <div class="filters">
          <form>
            <input @keyup="handleSearchSort" class="form-input" type="search" placeholder="rechercher" v-model="search">
            <select @change="handleSearchSort" class="form-select" v-model="sort">
              <option hidden value="">Afficher par :</option>
              <option value="DESC">Les plus récents</option>
              <option value="ASC">Les moins récents</option>
            </select>
            <router-link class="btn btn-red" :to="{ name: 'clientsAdd'}" title="Ajouter un nouveau client">+</router-link>
          </form>
        </div>

      </div>

      <div class="clients-listing-content">
        <div v-if="loading" class="table-container" role="table">
          <div class="client">
            <div class="client-infos">
              <div class="cell">Chargement ...</div>
            </div>
          </div>
        </div>
        <div v-else-if="clients.length" class="table-container" role="table">
          <div
              v-for="(client, clientIndex) in clients"
              :key="clientIndex"
              class="client" role="rowgroup">

              <div class="client-infos">
                <div
                  @click="$router.push({ name: 'clientsEdit', params: { clientId: client.id } })"
                  class="cell icon"
                  role="cell"
                >
                  <icon :icon="'eye'" title="Voir les informations du client" />
                </div>
                <div class="cell" role="cell">{{ client.companyName }}</div>
                <div class="cell" role="cell">{{ client.lastname }}</div>
                <div class="cell" role="cell">{{ client.firstname }}</div>
                <div class="cell" role="cell">{{ client.city }}</div>
                <div class="cell" role="cell">{{ client.email }}</div>
                <div
                  @click="showQuotes(client.id)"
                  class="cell icon"
                  :class="{ 'dark-background': client.id === visibleQuotesClient }"
                  role="cell"
                >
                  <icon :icon="'arrow-down-circle'" title="Voir les simulations du client" />
                </div>
              </div>
              <div v-show="client.id === visibleQuotesClient" class="client-quotes">
                <div class="quotes" :class="{ 'no-quote': !client.quotes.length }">
                  <div v-if="!client.quotes.length">Aucune simulation</div>
                  <div
                      v-for="(quote, quoteIndex) in client.quotes"
                      :key="quoteIndex"
                      class="quote">
                    <div class="cell title-quote" role="cell">{{ quote.title }}</div>
                    <div class="cell" role="cell">{{ quote.created }}</div>
                    <div class="cell" role="cell" title="Montant total du projet">{{ formatPrice(quote.prixHT) }} € HT</div>
                    <div class="cell font-mono" role="cell" title="Retour d’investissement sur 10 ans en % :">{{ quote.ri.length === 10 ? ' ' + Math.round(quote.ri[9] * 100).toLocaleString() + ' %'  : ' RI non calculé' }}</div>
                    <div class="cell icons" role="cell">

                      <modal
                        :open="['QuotesValidate', 'QuotesEditValidate', 'QuotesDuplicateValidate'].includes(from.name) &&
                        quote.id === savedQuote"
                        close-left
                        @open="onModalActivatorOpen(quote.id)"
                      >
                        <div slot="activator">
                          <icon :icon="'eye-white'" />
                        </div>
                        <Validate />
                      </modal>

                      <div @click="editQuote(client.id, quote.id)"><icon :icon="'pen-white'" /></div>
                      <div
                        @click="sendQuote(quote.id, client.email, quote.title)">
                        <icon :icon="'email-white'" />
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  @click="addQuote(client.id)"
                  class="btn btn-red edit-btn" title="Ajouter une nouvelle simulation">+</button>
              </div>
          </div>
        </div>
        <div v-else class="table-container" role="table">
          <div class="client">
            <div class="client-infos">
              <div class="cell">Aucun client</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/commons/PageTitle";
import Validate from "@/components/quotes/summary/Validate";

export default {
  components: {Validate, PageTitle},

  data: () => ({
    loading: false,
    visibleQuotesClient: null,
    search: '',
    sort: '',
    from: null
  }),

  computed: {
    clients() {
      return this.$store.state.clients.listing;
    },

    savedQuote() {
      return this.$store.state.clients.savedQuote
    }
  },

  methods: {
    showQuotes (clientId) {
      if(this.visibleQuotesClient === clientId) {
        this.visibleQuotesClient = null;
        return;
      }
      this.visibleQuotesClient = clientId;
    },

    handleSearchSort() {
      const params = { s: this.search, created: this.sort }
      this.$store.dispatch('getClients', params)
    },

    formatPrice(value) {
      return parseFloat(value)
      .toLocaleString(undefined, {minimumFractionDigits: 2})
    },

    async onModalActivatorOpen(quoteId) {
      await this.$store.dispatch('getQuote', quoteId);
      await this.$store.dispatch('getTaxos');
    },

    addQuote(clientId) {
      const client = this.clients.find((client) => {
        return client.id === clientId
      })
      const firstQuote = client?.quotes?.[0]

      if (firstQuote) {
        this.$router.push({ name: 'QuotesDuplicateElevage', params: { clientId, quoteId: firstQuote.id }})

      } else {
        this.$router.push({ name: 'QuotesElevage', params: { clientId }})
      }
    },

    editQuote(clientId, quoteId) {
      this.$router.push({ name: 'QuotesEditElevage', params: { clientId, quoteId }})
    },

    sendQuote(id, to, filename) {
      this.$store.dispatch('sendQuote', { id, to, filename})
    }
  },

  async beforeMount() {
    this.loading = true
    await this.$store.dispatch('getClients')
    this.loading = false
    if (this.from &&
      ['QuotesValidate', 'QuotesEditValidate', 'QuotesDuplicateValidate'].includes(this.from.name)) {
      this.visibleQuotesClient = parseFloat(this.from.params.clientId)
      
    }
  },

  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.from = from
    })
  },

}
</script>

<style lang="scss" scoped>
.font-mono {
  font-family: monospace;
  font-size: 12px;
}

@media screen and (max-width: 640px) {
  .clients-listing-content .table-container .client-quotes .quotes .quote .cell.title-quote {
    text-align: center;
    display: block;
    width: 240px;
    word-wrap: break-word;
  }
}

</style>
