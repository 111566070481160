import Vue from 'vue'
import Cookies from "js-cookie";
import VueRouter from 'vue-router'
import store from '@/store'

import Home from '@/views/pages/Home/index.vue'
import Login from '@/views/authentication/Login.vue'
import NotFound from '@/views/errors/404.vue'
import AccessDenied from '@/views/errors/403.vue'

import Clients from "@/views/pages/Clients/Index";
import ClientsListing from "@/components/clients/Listing";
import ClientsForm from "@/components/clients/forms/Add";

import Quotes from "@/views/pages/Quotes/Index";
import QuotesBatiment from "@/components/quotes/forms/Batiment";
import QuotesLogettes from "@/components/quotes/forms/Logettes";
import QuotesElevage from "@/components/quotes/forms/Elevage";
import QuotesProduits from "@/components/quotes/forms/Produits";
import QuotesValidate from "@/components/quotes/summary/Validate";

Vue.use(VueRouter)

const routes = [
  // Page d'accueil
  {
    path: '/',
    name: 'home',
    component: Home
  },

  // Page d'authentication
  {
    path: '/login',
    name: 'login',
    component: Login
  },

  {
    path: '/clients',
    component: Clients,
    children: [
      {
        path: '',
        name: 'clientsListing',
        component: ClientsListing,
        meta: {
          title: 'Vos <strong>clients<strong>'
        }
      },
      {
        path: 'add',
        name: 'clientsAdd',
        component: ClientsForm,
        meta: {
          title: '<strong>Création</strong> du profil <strong>client</strong>'
        }
      },
      {
        path: ':clientId',
        name: 'clientsEdit',
        component: ClientsForm,
        meta: {
          title: 'Profil <strong>client</strong>'
        },
      },
      {
        path: ':clientId/quote',
        component: Quotes,
        children: [
          {
            path: 'add/elevage',
            name: 'QuotesElevage',
            component: QuotesElevage,
            meta: {
              title: 'Découverte de <strong>l\'élevage</strong>',
              introduction: 'La simulation ne tient compte que des informations renseignées ici.',
              icon: 'jumelle'
            }
          },
          {
            path: 'add/logettes-couloirs',
            name: 'QuotesLogettes',
            component: QuotesLogettes,
            meta: {
              title: 'Informations <strong>logettes</strong> et <strong>couloirs de circulation</strong>',
              introduction: '',
              icon: 'informations'
            }
          },
          {
            path: 'add/batiment',
            name: 'QuotesBatiment',
            component: QuotesBatiment,
            meta: {
              title: 'Informations <strong>bâtiment</strong>',
              introduction: '',
              icon: 'jumelle'
            }
          },
          {
            path: 'add/produits',
            name: 'QuotesProduits',
            component: QuotesProduits,
            meta: {
              title: 'Sélection des <strong>produits</strong>',
              introduction: ''
            }
          },
          {
            path: 'add/summary',
            name: 'QuotesValidate',
            component: QuotesValidate,
            meta: {
              title: 'Informations <strong>simulation</strong>'
            }
          },
          {
            path: ':quoteId/elevage',
            name: 'QuotesEditElevage',
            component: QuotesElevage,
            meta: {
              title: 'Découverte de <strong>l\'élevage</strong>',
              introduction: 'La simulation ne tient compte que des informations renseignées ici.',
              icon: 'jumelle'
            }
          },
          {
            path: ':quoteId/logettes-couloirs',
            name: 'QuotesEditLogettes',
            component: QuotesLogettes,
            meta: {
              title: 'Informations <strong>logettes</strong> et <strong>couloirs de circulation</strong>',
              introduction: '',
              icon: 'informations'
            }
          },
          {
            path: ':quoteId/batiment',
            name: 'QuotesEditBatiment',
            component: QuotesBatiment,
            meta: {
              title: 'Informations <strong>bâtiment</strong>',
              introduction: '',
              icon: 'jumelle'
            }
          },
          {
            path: ':quoteId/produits',
            name: 'QuotesEditProduits',
            component: QuotesProduits,
            meta: {
              title: 'Sélection des <strong>produits</strong>',
              introduction: ''
            }
          },
          {
            path: ':quoteId/summary',
            name: 'QuotesEditValidate',
            component: QuotesValidate,
            meta: {
              title: 'Informations <strong>simulation</strong>'
            }
          },
          {
            path: ':quoteId/duplicate/elevage',
            name: 'QuotesDuplicateElevage',
            component: QuotesElevage,
            meta: {
              title: 'Découverte de <strong>l\'élevage</strong>',
              introduction: 'La simulation ne tient compte que des informations renseignées ici.',
              icon: 'jumelle'
            }
          },
          {
            path: ':quoteId/duplicate/logettes-couloirs',
            name: 'QuotesDuplicateLogettes',
            component: QuotesLogettes,
            meta: {
              title: 'Informations <strong>logettes</strong> et <strong>couloirs de circulation</strong>',
              introduction: '',
              icon: 'informations'
            }
          },
          {
            path: ':quoteId/duplicate/batiment',
            name: 'QuotesDuplicateBatiment',
            component: QuotesBatiment,
            meta: {
              title: 'Informations <strong>bâtiment</strong>',
              introduction: '',
              icon: 'jumelle'
            }
          },
          {
            path: ':quoteId/duplicate/produits',
            name: 'QuotesDuplicateProduits',
            component: QuotesProduits,
            meta: {
              title: 'Sélection des <strong>produits</strong>',
              introduction: ''
            }
          },
          {
            path: ':quoteId/duplicate/summary',
            name: 'QuotesDuplicateValidate',
            component: QuotesValidate,
            meta: {
              title: 'Informations <strong>simulation</strong>'
            }
          }
        ]
      }
    ]
  },

  // Page 404
  {
    path: '/404',
    component: NotFound,
    meta: {
      title: 'Introuvable - Mercurial'
    }
  },

  // Page 403
  {
    path: '/403',
    component: AccessDenied,
    meta: {
      title: 'Introuvable - Mercurial'
    }
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  window.scrollTo(0, 0)

  if (to.name !== 'login' && to.name !== 'home') {

    const parseJwt = (token) => {
      var base64Url = token.split('.')[1]
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))
      return JSON.parse(jsonPayload)
    }

    var hasPermission = false;
    if (store.getters.accessToken) {
      const parsedToken = parseJwt(store.getters.accessToken)
      if (Date.now() < parsedToken.exp * 1000) {
        // const refreshToken = this.$store.getters.refreshToken ? this.$store.getters.refreshToken : ''
        // this.$store.dispatch('refreshAccessToken', refreshToken)
        hasPermission = true
      }
    }

    if (!hasPermission) {
      store.commit('CONNECT_USER', false)
      store.commit('SET_ACCESS_TOKEN', null)
      store.commit('SET_REFRESH_TOKEN', null)
      store.commit('SET_USER', {
        roles: [
          'guest'
        ]
      })

      // Les cookies sont effacés
      Object.keys(Cookies.get()).map(cookieName => Cookies.remove(cookieName))

      Vue.notify({
        group: 'notification',
        text: 'Session expirée',
        type: 'warn'
      })

      next({
        path: '/login',
        query: {redirect: to.fullPath}
      })
    }
  } else if(store.getters.isAuthenticated) {
    next({
      name: 'clientsListing'
    })
  }
  next()
})

export default router
