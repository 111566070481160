const SET_ELEVAGE_VALUE = (state, value) => {
  state.current.elevage = value;
}

const SET_LOGETTES_VALUE = (state, value) => {
  state.current.logettes = value;
}

const SET_BATIMENT_VALUE = (state, value) => {
  state.current.batiment = value;
}

const SET_PRODUITS_VALUE = (state, value) => {
  state.current.produits = value;
}

const SET_CLIENT_ID = (state, value) => {
  state.current.client.id = value;
}

const SET_TAXOS = (state, value) => {
  state.taxos = value;
}

const SET_PRODUITS = (state, value) => {
  state.produits = value;
}

const SET_STATIONS_METEO = (state, value) => {
  state.stationsMeteo = value;
}

const SET_RI_SETTINGS = (state, value) => {
  state.riSettings = value;
}

const SET_RI = (state, value) => {
  state.current.ri = value;
}

const SET_PRIX_HT = (state, value) => {
  state.current.prixHT = value;
}

const SET_GAINS = (state, value) => {
  state.current.gains = value;
}

const SET_SEUIL_RENTABILITE = (state, value) => {
  state.current.seuilRentabilite = value;
}

const SET_RENTABILISATION_ANNEE = (state, value) => {
  state.current.rentabilisationAnnee = value;
}

const SET_TRESORERIE7 = (state, value) => {
  state.current.tresorerie7 = value;
}

const SET_TRESORERIE10 = (state, value) => {
  state.current.tresorerie10 = value;
}

const SET_CUMULISTHVLTEST = (state, value) => {
  state.current.cumulISTHVLtest = value;
}

const SET_SOMMEIMPACTLITRELAIT = (state, value) => {
  state.current.sommeImpactLitreLait = value;
}

const SET_SOMMEECONOMIEVETERINAIRE = (state, value) => {
  state.current.sommeEconomieVeterinaire = value;
}

const SET_SOMMEIMPACTISTHVL = (state, value) => {
  state.current.sommeImpactIsthvl = value;
}

const SET_SOMMEIMPACTLACTATION = (state, value) => {
  state.current.sommeImpactLactation = value;
}
const SET_SOMMECOUTVETERINAIRE = (state, value) => {
  state.current.sommeCoutVeterinaire = value;
}
const SET_SOMMEIMPACTPONDERESLITRELAIT = (state, value) => {
  state.current.sommeImpactPonderesLitreLait = value;
}
const SET_SOMMEIMPACTPONDERESLACTATION = (state, value) => {
  state.current.sommeImpactPonderesLactation = value;
}

const SET_POURCENTAGECUMULEFFETS = (state, value) => {
  state.current.pourcentageCumuleEffets = value;
}

const SET_LAITANNE1 = (state, value) => {
  state.current.litreLaitAnnee1 = value;
}

const SET_LAITANNE2 = (state, value) => {
  state.current.litreLaitAnnee2 = value;
}

const SET_LAITANNE3 = (state, value) => {
  state.current.litreLaitAnnee3 = value;
}


const SET_LACTATIONANNE1 = (state, value) => {
  state.current.lactationAnnee1 = value;
}

const SET_LACTATIONANNE2 = (state, value) => {
  state.current.lactationAnnee2 = value;
}

const SET_LACTATIONANNE3 = (state, value) => {
  state.current.lactationAnnee3 = value;
}


const SET_REDUCFRAISVETOANNE1 = (state, value) => {
  state.current.reductionFraisVeterinairesAnnee1 = value;
}

const SET_REDUCFRAISVETOANNE2 = (state, value) => {
  state.current.reductionFraisVeterinairesAnnee2 = value;
}

const SET_REDUCFRAISVETOANNE3 = (state, value) => {
  state.current.reductionFraisVeterinairesAnnee3 = value;
}

const SET_GAINS10ANS = (state, value) => {
  state.current.gains10ans = value;
}


const SET_QUOTE = (state, value) => {
  state.current = value;
}

export default {
  SET_ELEVAGE_VALUE,
  SET_LOGETTES_VALUE,
  SET_BATIMENT_VALUE,
  SET_PRODUITS_VALUE,
  SET_CLIENT_ID,
  SET_TAXOS,
  SET_PRODUITS,
  SET_STATIONS_METEO,
  SET_RI_SETTINGS,
  SET_RI,
  SET_PRIX_HT,
  SET_GAINS,
  SET_SEUIL_RENTABILITE,
  SET_RENTABILISATION_ANNEE,
  SET_TRESORERIE7,
  SET_TRESORERIE10,
  SET_QUOTE,
  SET_CUMULISTHVLTEST,
  SET_SOMMEIMPACTLITRELAIT,
  SET_SOMMEECONOMIEVETERINAIRE,
  SET_SOMMEIMPACTISTHVL,
  SET_SOMMECOUTVETERINAIRE,
  SET_SOMMEIMPACTLACTATION,
  SET_SOMMEIMPACTPONDERESLACTATION,
  SET_POURCENTAGECUMULEFFETS,
  SET_SOMMEIMPACTPONDERESLITRELAIT,
  SET_LAITANNE2,
  SET_LAITANNE3,
  SET_LAITANNE1,
  SET_LACTATIONANNE1,
  SET_LACTATIONANNE2,
  SET_LACTATIONANNE3,
  SET_REDUCFRAISVETOANNE1,
  SET_REDUCFRAISVETOANNE2,
  SET_REDUCFRAISVETOANNE3,
  SET_GAINS10ANS
}
