import axios from '@/axios'
import Vue from "vue";
import router from '@/router'

const getClients = async ({ commit }, params) => {
  try {
    const clients = await axios.get('/api/v1/clients/search', { params })
    commit('SET_CLIENTS', clients.data)
  } catch (e) {
    Vue.notify({
      group: 'notification',
      text: 'Une erreur est survenue lors de la récupération de la liste des clients',
      type: 'error'
    })
  }
}

const getClient = async ({ commit }, id) => {
  try {
    const client = await axios.get('/api/v1/clients/' + id)
    commit('SET_CLIENT', client.data)
  } catch (e) {
    Vue.notify({
      group: 'notification',
      text: 'Une erreur est survenue lors de la récupération du client',
      type: 'error'
    })
  }
}

const postClient = async ({ state}) => {
  try {
    const response = await axios.post('/api/v1/create_client', state.current)
    const clientID = parseFloat(response.data.idClient)
    Vue.notify({
      group: 'notification',
      text: 'Le client a bien été créé',
      type: 'success'
    })
    router.push('/clients/'+clientID+'/quote/add/elevage')
  } catch (e) {
    Vue.notify({
      group: 'notification',
      text: 'Une erreur est survenue lors de la création du client',
      type: 'error'
    })
  }
}

const patchClient = async ({ state }) => {
  try {
    await axios.patch('/api/v1/update_client', state.current)

    Vue.notify({
      group: 'notification',
      text: 'Le client a bien été mis à jour',
      type: 'success'
    })
  } catch (e) {
    Vue.notify({
      group: 'notification',
      text: 'Une erreur est survenue lors de la mise à jour du client',
      type: 'error'
    })
  }
}

const resetClientsState = async ({ commit }) => {
  commit('RESET_CLIENTS_STATE')
}

export default {
  getClients,
  getClient,
  postClient,
  patchClient,
  resetClientsState
}
