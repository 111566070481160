import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const getDefaultState = () => {
  return {
    taxos: {},
    produits: {},
    stationsMeteo: {},
    riSettings: {},
    current: {
      elevage: {
        nombreLogettes: '',
        nbrVachesTraite: '',
        nbrVachesTaries: '',
        nbrGenisses: '',
        venteGenisses: '',
        nbrLitresMoyen: '',
        prixLitreLait: '',
        nbrPrimipares: '',
        typeTraite: '',
        prctPresenceEtable: '',
        nbrLactationsMoyen: '',
        stationMeteo: ''
      },
      logettes: {
        largeurLogette: '',
        couchage: '',
        zoneCaillouteuse: '',
        typeSols: '',
        typeRaclage: '',
        nbrPlaceCornadis: '',
        couloirs: [{
          longueur: '',
          largeur: '',
          qte: '',
        }]
      },
      batiment: {
        typeVentilation: '',
        typeBatiment: '',
        isolationToiture: ''
      },
      produits: [
        {
          produit: '',
          qte: '',
          name: '',
          prix: ''
        },
        {
          produit: '',
          qte: '',
          name: '',
          prix: ''
        },
        {
          produit: '',
          qte: '',
          name: '',
          prix: ''
        },
        {
          produit: '',
          qte: '',
          name: '',
          prix: ''
        },
        {
          produit: '',
          qte: '',
          name: '',
          prix: ''
        }
      ],
      ri: [],
      prixHT: 0,
      gains: 0,
      seuilRentabilite: 0,
      rentabilisationAnnee: '',
      cumulISTHVLtest: '',
      sommeImpactLitreLait: '',
      sommeEconomieVeterinaire: '',
      sommeImpactLactation: '',
      sommeImpactIsthvl: '',
      sommeCoutVeterinaire: '',
      sommeImpactPonderesLactation: '',
      sommeImpactPonderesLitreLait: '',
      pourcentageCumuleEffets: '',
      litreLaitAnnee2: '',
      litreLaitAnnee1: '',
      litreLaitAnnee3: '',
      lactationAnnee1: '',
      lactationAnnee2: '',
      lactationAnnee3: '',
      reductionFraisVeterinairesAnnee1: '',
      reductionFraisVeterinairesAnnee2: '',
      reductionFraisVeterinairesAnnee3: '',
      tresorerie7: [],
      tresorerie10: [],
      gains10ans: [],
      client: {
        id: ''
      }
    },
  }
}

const state = getDefaultState()

mutations.RESET_PRODUITS_VALUE = (state) => {
  state.current.produits = getDefaultState().current.produits
}

mutations.RESET_QUOTES_STATE = (state) => {
  Object.assign(state, getDefaultState())
}

export default ({
  state: state,
  actions,
  mutations,
  getters
})
