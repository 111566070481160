<template>
  <div class="main">
    <div class="client-form">
      <div class="edit-client">
        <button v-if="disabled" @click="disabled = false" class="btn btn-transparent btn-small">
          Modifier
        </button>
      </div>
      <PageTitle />
      <form>

        <div class="form-row form-row-3">
            <div class="form-field">
              <input
                  placeholder="Raison sociale :"
                  type="text"
                  v-model.trim="$v.client.companyName.$model"
                  :disabled="disabled"
                  class="form-input" :class="{ 'border-red-700': $v.client.companyName.$error, 'disabled': disabled }">
                <span>Raison sociale : *</span>
            </div>
            <div class="form-field">
              <input
                  placeholder="Nom :"
                  type="text"
                  :disabled="disabled"
                  v-model.trim="$v.client.lastname.$model"
                  class="form-input" :class="{ 'border-red-700': $v.client.lastname.$error, 'disabled': disabled }">
              <span>Nom : *</span>
            </div>
            <div class="form-field">
              <input
                  placeholder="Prénom :"
                  type="text"
                  :disabled="disabled"
                  v-model.trim="$v.client.firstname.$model"
                  class="form-input" :class="{ 'border-red-700': $v.client.firstname.$error, 'disabled': disabled }">
              <span>Prénom : *</span>
            </div>
        </div>
        <div class="form-row">
          <div class="form-field">
            <input
                placeholder="Adresse :"
                type="text"
                :disabled="disabled"
                v-model.trim="$v.client.address.$model"
                class="form-input" :class="{ 'border-red-700': $v.client.address.$error, 'disabled': disabled }">
            <span>Adresse :</span>
          </div>
        </div>
        <div class="form-row form-row-3">
            <div class="form-field">
              <input
                  placeholder="Code postal :"
                  type="text"
                  :disabled="disabled"
                  :minlength=5
                  v-model.trim="$v.client.postalCode.$model"
                  class="form-input" :class="{ 'border-red-700': $v.client.postalCode.$error, 'disabled': disabled }">
              <span>Code postal : *</span>
            </div>
            <div class="form-field">
              <input
                  placeholder="Ville :"
                  type="text"
                  :disabled="disabled"
                  v-model.trim="$v.client.city.$model"
                  class="form-input" :class="{ 'border-red-700': $v.client.city.$error, 'disabled': disabled }">
              <span>Ville : *</span>
            </div>
            <div class="form-field">
              <input
                  placeholder="Pays :"
                  type="text"
                  :disabled="disabled"
                  v-model.trim="$v.client.country.$model"
                  class="form-input" :class="{ 'border-red-700': $v.client.country.$error, 'disabled': disabled }">
              <span>Pays : *</span>
            </div>
        </div>
        <div class="form-row form-row-2">
          <div class="form-field">
            <input
                placeholder="Téléphone :"
                type="tel"
                :disabled="disabled"
                v-model.trim="$v.client.phone.$model"
                class="form-input" :class="{ 'border-red-700': $v.client.phone.$error, 'disabled': disabled }">
            <span>Téléphone : *</span>
          </div>
          <div class="form-field">
            <input
                placeholder="Adresse email :"
                type="email"
                :disabled="disabled"
                v-model.trim="$v.client.email.$model"
                class="form-input" :class="{ 'border-red-700': $v.client.email.$error, 'disabled': disabled }">
            <span>Adresse email : *</span>
          </div>
        </div>
        <div class="form-submit">
          <button
              v-if="!disabled"
              class="btn btn-red uppercase my-5"
              @click.prevent="handleSubmit">
                Valider
          </button>
        </div>
        <div class="text-mentions">
          <p>* mention obligatoire</p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/commons/PageTitle";
import { required, email, numeric, minLength } from 'vuelidate/lib/validators'
import BackBtn from "@/components/commons/BackBtn";
import Vue from "vue";

export default {
  components: {BackBtn, PageTitle},

  data: function () {
    return {
      disabled: this.$route.name === 'clientsEdit'
    }
  },

  computed: {
    client () {
      return this.$store.state.clients.current
    }
  },

  validations: {
    client: {
      
      companyName: {
        required
      },
      lastname: {
        required
      },
      firstname: {
        required
      },
      address: {},
      postalCode : {
        numeric,
        required,
        minLength: minLength(5)
      },
      city: {
        required
      },
      country: {
        required
      },
      phone: {
        required
      },
      email: {
        email,
        required
      },
    }
  },

  methods: {
    async handleSubmit () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if(this.$route.name === 'clientsEdit') {
          await this.$store.dispatch('patchClient')
        } else {
          await this.$store.dispatch('postClient')
        }
      }
    }
  },

  beforeMount() {
    if(this.$route.name === 'clientsEdit') {
      this.$store.dispatch('getClient', this.$route.params.clientId)
    } else {
      this.$store.dispatch('resetClientsState')
    }
  }
}
</script>

<style lang="scss" scoped>
  .client-form {
    @apply w-full;

    .edit-client {
      @apply flex flex-row justify-center items-center mt-6;
    }

    h1 {
      @apply my-8;
    }
  }
  .text-mentions {
  display: block;
  width: 100%;
  text-align: left;
}
</style>
